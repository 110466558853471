import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faBell,
  faClock,
  faCoffee,
  faCube,
  faAd,faPlus,
  faConciergeBell,
  faWrench,
  faTable,faTasks,
  faChartLine,faStore,faCubes,faChevronDown,faBiking,
  faCalendarAlt,faWalking,faBars,faPen,faTrashAlt,faFighterJet,faGlobe,faObjectGroup, faToggleOn,faToggleOff,faCloudUploadAlt, faCamera,faChair,faPercent,faShare,faSitemap,
  faPlusCircle,faCheckSquare,faMoneyBill,faQrcode,faArrowDown,faArrowUp,faCirclePlay,faPrint,faMailBulk,faCopy

} from "@fortawesome/free-solid-svg-icons";

library.add(faHome, faUser, faUserPlus, faPlus,
            faSignInAlt, faSignOutAlt,faBell,
            faClock,faCoffee,faCube,
            faConciergeBell,faWrench,faTable
            ,faTasks,faChartLine,faStore,faCubes,
            faChevronDown,faBiking,faCalendarAlt,
            faWalking,faBars,faPen,faTrashAlt,faFighterJet,faToggleOn,faToggleOff, faCloudUploadAlt, faCamera,faCheckSquare,faChair,faShare,faSitemap,
            faGlobe,faObjectGroup,faPlusCircle,faPercent,faMoneyBill,faQrcode,faArrowDown,faArrowUp,faCirclePlay,faPrint,faMailBulk,faCopy);

export { FontAwesomeIcon };
