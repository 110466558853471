import { createStore } from "vuex";
import { auth } from "./auth.module";


import * as getters from './storeOrder/getters'
import * as actions from './storeOrder/actions'
import * as mutations from './storeOrder/mutations'
import state from './storeOrder/state'


const store = createStore({

  state,
  getters,
  mutations,
  actions,

  modules: {
    auth,
  },
});

export default store;
