import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04009ad7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = { class: "groupContent" }
const _hoisted_5 = { class: "groupName" }
const _hoisted_6 = {
  key: 0,
  class: "groupRequired"
}
const _hoisted_7 = {
  key: 0,
  class: "elementRadio radio-color"
}
const _hoisted_8 = {
  key: 1,
  class: "elementCheckbox checkbox-color"
}
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "row content mb_25" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "mt-2 mb-2" }
const _hoisted_13 = ["alt", "src"]
const _hoisted_14 = { class: "mt-2 mb-2" }
const _hoisted_15 = { class: "mb-2" }
const _hoisted_16 = { class: "col-12" }
const _hoisted_17 = { class: "btnPlusMines mb_25" }
const _hoisted_18 = { class: "QteProduct" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = ["disabled"]
const _hoisted_21 = { class: "d-block labelAddToCart" }
const _hoisted_22 = ["disabled"]
const _hoisted_23 = { class: "d-block labelAddToCart" }
const _hoisted_24 = {
  key: 0,
  class: "promo-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_CheckboxButton = _resolveComponent("CheckboxButton")!
  const _component_PlusMinusButton = _resolveComponent("PlusMinusButton")!
  const _component_promos_component = _resolveComponent("promos-component")!
  const _component_CustomModal = _resolveComponent("CustomModal")!

  return ($data.isModalAddToCartOpen)
    ? (_openBlock(), _createBlock(_component_CustomModal, {
        key: 0,
        onClose: $options.closeModal,
        modalsize: $props.product?.groups.length > 0 ? 'modal-lg' : 'modal-sm'
      }, {
        header: _withCtx(() => []),
        content: _withCtx(() => [
          _createElementVNode("form", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              ($props.product?.groups.length > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([$props.product?.ui?.columns?.col_1, "sub-products"])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.product?.groups, (group, indexg) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: indexg,
                        class: "row mb_25"
                      }, [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("h5", _hoisted_5, [
                              _createTextVNode(_toDisplayString(group.title) + " ", 1),
                              (group.required)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Obligatoire "))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          (group.button_type == 'RadioButton')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.sub_products, (subProduct, indexSub) => {
                                  return (_openBlock(), _createElementBlock("label", {
                                    key: indexSub,
                                    class: "radio red"
                                  }, [
                                    _createVNode(_component_RadioButton, {
                                      product: $props.product,
                                      group: group,
                                      subProduct: subProduct
                                    }, null, 8, ["product", "group", "subProduct"])
                                  ]))
                                }), 128))
                              ]))
                            : (group.button_type == 'CheckboxButton')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.sub_products, (subProduct, indexSub) => {
                                    return (_openBlock(), _createElementBlock("label", {
                                      key: indexSub,
                                      class: "checkbox red"
                                    }, [
                                      _createVNode(_component_CheckboxButton, {
                                        product: $props.product,
                                        group: group,
                                        subProduct: subProduct
                                      }, null, 8, ["product", "group", "subProduct"])
                                    ]))
                                  }), 128))
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.sub_products, (subProduct, indexSub) => {
                                    return (_openBlock(), _createElementBlock("div", { key: indexSub }, [
                                      _createVNode(_component_PlusMinusButton, {
                                        product: $props.product,
                                        group: group,
                                        subProduct: subProduct
                                      }, null, 8, ["product", "group", "subProduct"])
                                    ]))
                                  }), 128))
                                ]))
                        ])
                      ]))
                    }), 128))
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass([$props.product?.ui?.columns?.col_2, "producInfo position-relative"])
              }, [
                _createElementVNode("figure", {
                  class: _normalizeClass([[$props.product?.promos[0] !== undefined ? 'pt-4' : ''], "text-center h-100 position-relative"])
                }, [
                  ($props.product.promos.length > 0)
                    ? (_openBlock(), _createBlock(_component_promos_component, {
                        key: 0,
                        promos: $props.product.promos,
                        className: "promo-product"
                      }, null, 8, ["promos"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("img", {
                          class: _normalizeClass(["zoom ing-fluid w-100", [$props.product?.groups.length > 0 ? 'width-70-per' : 'width-70-px',]]),
                          alt: $props.product?.name,
                          src: $options.getImageUrl($props.product.path_image),
                          onError: _cache[0] || (_cache[0] = ($event: any) => (
                                                $options.setDefaultImage(
                                                    $event,
                                                    '~/assets/img/default-burger.webp',
                                                    $options.getImageUrl($props.product.path_image)
                                                )
                                                ))
                        }, null, 42, _hoisted_13),
                        _createElementVNode("h1", _hoisted_14, [
                          _createElementVNode("strong", null, _toDisplayString($props.product?.category_name), 1)
                        ]),
                        _createElementVNode("h5", _hoisted_15, _toDisplayString($props.product?.name), 1),
                        _createElementVNode("p", null, _toDisplayString($props.product?.ingredient_names), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["blocAddToCart", [$props.product?.groups.length > 0 ? 'position-absolute' : 'position-relative']])
                  }, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["dis-block", [$props.product?.groups.length > 0 ? 'pos-absolute' : '']])
                      }, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("span", {
                            class: _normalizeClass(["btnQte", {
                                                'btnDisabled cursorNoDrop ': $props.product?.quantity == 1,
                                            }]),
                            onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.AddRemoveQteProduct('minus')))
                          }, " - ", 2),
                          _createElementVNode("span", _hoisted_18, [
                            ($props.product?.quantity)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString($props.product?.quantity), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("span", {
                            class: "btnQte",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.AddRemoveQteProduct('plus')))
                          }, " + ")
                        ]),
                        ($props.action == 'add')
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              type: "button",
                              class: _normalizeClass([{
                                            'btn-cart-disabled cursorNoDrop': $props.product?.enabled_to_order,
                                        }, "btnAddToCart ctaBtn mb_5"]),
                              disabled: $props.product?.enabled_to_order,
                              onClick: _cache[3] || (_cache[3] = ($event: any) => ($options.AddProductToCart()))
                            }, [
                              _createElementVNode("label", _hoisted_21, " Ajouter " + _toDisplayString($props.product?.quantity) + " pour ", 1),
                              _createTextVNode(" " + _toDisplayString(parseFloat($props.product?.total).toFixed(2)) + " € ", 1)
                            ], 10, _hoisted_20))
                          : (_openBlock(), _createElementBlock("button", {
                              key: 1,
                              type: "button",
                              class: _normalizeClass([{
                                            'btn-cart-disabled cursorNoDrop': $props.product?.enabled_to_order,
                                        }, "btnAddToCart ctaBtn mb_5"]),
                              disabled: $props.product?.enabled_to_order,
                              onClick: _cache[4] || (_cache[4] = ($event: any) => ($options.UpdateProductInCart()))
                            }, [
                              _createElementVNode("label", _hoisted_23, " Modifier " + _toDisplayString($props.product?.quantity) + " pour ", 1),
                              _createTextVNode(" " + _toDisplayString(parseFloat($props.product?.total).toFixed(2)) + " € ", 1)
                            ], 10, _hoisted_22)),
                        _createElementVNode("div", null, [
                          ($props.product.promos.length > 0)
                            ? (_openBlock(), _createElementBlock("small", _hoisted_24, "* la promo s'applique sur l’écran suivant"))
                            : _createCommentVNode("", true)
                        ])
                      ], 2)
                    ])
                  ], 2)
                ], 2)
              ], 2)
            ])
          ])
        ]),
        _: 1
      }, 8, ["onClose", "modalsize"]))
    : _createCommentVNode("", true)
}