import axios from 'axios';
import store from '../../store/index';
import VueSweetalert2 from 'vue-sweetalert2';
import Swal from 'sweetalert2';
import i18n from '../../i18n/index'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-right',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true
})
axios.interceptors.response.use((response) => {
  if (response.status === 204) {
    // Toast.fire({
    //   icon: 'success',
    //   title: "L'opération a été exécutée avec succès"
    // })
  }
  if (response.status === 200) {
    if ((response.data?.message?.includes('updated') || response.data?.message?.includes('successfully')) && !(response.data?.message?.includes('signin') || response.data?.message?.includes('Logout'))) {
      //   Toast.fire({
      //     icon: 'success',
      //     title: "L'opération a été exécutée avec succès"
      //   })
    }
  }
  return response;
}, async (error) => {






  let MessageError = ''
  let validatoreKey = ''
  let values = []
  let inputName = ''
  if (error?.response?.data?.errors) {
    Object.keys(error.response?.data?.errors).forEach(function (attribute, index) {
      error.response?.data?.errors[attribute]?.map((item, index) => {
        // Split the string by dots
        let parts = item.split('.');
        validatoreKey = parts.slice(0, 2).join('.');
        values = parts.slice(2) || [];
        inputName = i18n.t("validator.attributes." + attribute)
        MessageError = "- " + i18n.t("validator." + validatoreKey, { attribute: inputName, value: values[0] || null }) + "\n" + MessageError
      })
    });
  } else {
    MessageError = error?.response?.data?.message || 'Somthing wrong, try again'
  }

  if (error) {
    if (error.response?.status === 401) {
      await Toast.fire({
        icon: 'error',
        title: MessageError
      })
      localStorage.removeItem('user')
      store.commit('auth/logout')
    }
    else if (error.response?.status === 422) {

      await Toast.fire({
        icon: 'warning',
        title: MessageError,
      })
    }
    else if (error.response?.status === 404) {
      await Toast.fire({
        icon: 'error',
        title: MessageError
      })
    }
    // else {
    //   await Toast.fire({
    //     icon: 'error',
    //     title: error
    //   })
    // }
  }
  return Promise.reject(error);
});
