
import ProductUtils from "../../../utils/ProductUtils";

export default {
  props: ["product", "group", "subProduct"],
  data() {
    return {};
  },
  created() { },
  methods: {
    CheckboxButtonSubProduct(product, group, subProduct) {
      if ((group?.total_qte_sub_products < group?.max) || subProduct?.quantity > 0) {
        subProduct.checked = !subProduct?.checked;
        product.total = ProductUtils.totalProduct(product);
        product.ingredient_names = ProductUtils.concatenateNamesIngredients(
          group
        );
        group.used = false;
        if (subProduct.checked) {
          subProduct.quantity = 1
          group.total_qte_sub_products += 1;
        } else {
          subProduct.quantity = 0
          group.total_qte_sub_products -= 1
        }
      }
    },
  },
  computed: {

  },
};
