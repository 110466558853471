import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ac9b475"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "elementCheckbox checkboxColor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.CheckboxButtonSubProduct($props.product, $props.group, $props.subProduct))),
      class: _normalizeClass([{
      'checked': $props.subProduct.checked, 'checkboxDisabled cursor-no-drop ': ($props.group.total_qte_sub_products == $props.group.max) && $props.subProduct.quantity == 0
    }, "font-weight-300 first-letter-maj"])
    }, _toDisplayString($props.subProduct?.ingredient?.name || $props.subProduct?.supplement?.name || $props.subProduct?.name), 3)
  ]))
}