<template>
  <nav class="navbar">
    <a class="sidebar-toggler" @click.prevent="toggle">
      <font-awesome-icon icon="bars" size="lg" id="messageDropdown" role="button" data-bs-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" />
    </a>
    <div class="navbar-content">
      <ul class="navbar-nav">
        <li class="nav-item dropdown" v-if="stripeLink">
          <a :href="stripeLink" class="bg-danger ">
            <span class="p-5 text-wh">Activer mon compte Stripe </span>
          </a>

        </li>
        <li class="nav-item dropdown" v-if="false">
          <a class="nav-link dropdown-toggle" href="#" id="languageDropdown" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="flag-icon flag-icon-fr mt-1" title="fr"></i>
            <span class="ms-1 me-1 d-none d-md-inline-block">French</span>
          </a>
          <div class="dropdown-menu" aria-labelledby="languageDropdown">
            <a href="javascript:;" class="dropdown-item py-2"><i class="flag-icon flag-icon-us" title="us" id="us"></i>
              <span class="ms-1"> English </span></a>
            <a href="javascript:;" class="dropdown-item py-2"><i class="flag-icon flag-icon-fr" title="fr" id="fr"></i>
              <span class="ms-1"> French </span></a>
          </div>
        </li>
        <!-- <li class="nav-item dropdown">
          <font-awesome-icon
            icon="table"
            size="lg"
            id="appsDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu p-0" aria-labelledby="appsDropdown">
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom"
            >
              <p class="mb-0 fw-bold">Web Apps</p>
              <a href="javascript:;" class="text-muted">Edit</a>
            </div>
            <div class="row g-0 p-1">
              <div class="col-3 text-center">
                <a
                  href="pages/apps/chat.html"
                  class="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"
                  ><i data-feather="message-square" class="icon-lg mb-1"></i>

                  <p class="tx-12">Chat</p></a
                >
              </div>
              <div class="col-3 text-center">
                <a
                  href="pages/apps/calendar.html"
                  class="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"
                  ><i data-feather="calendar" class="icon-lg mb-1"></i>
                  <p class="tx-12">Calendar</p></a
                >
              </div>
              <div class="col-3 text-center">
                <a
                  href="pages/email/inbox.html"
                  class="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"
                  ><i data-feather="mail" class="icon-lg mb-1"></i>
                  <p class="tx-12">Email</p></a
                >
              </div>
              <div class="col-3 text-center">
                <a
                  href="pages/general/profile.html"
                  class="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"
                  ><i data-feather="instagram" class="icon-lg mb-1"></i>
                  <p class="tx-12">Profile</p></a
                >
              </div>
            </div>
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-center border-top"
            >
              <a href="javascript:;">View all</a>
            </div>
          </div>
        </li> -->
        <!-- <li class="nav-item dropdown">
          <font-awesome-icon
            icon="tasks"
            size="lg"
            id="messageDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />

          <div class="dropdown-menu p-0" aria-labelledby="messageDropdown">
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom"
            >
              <p>9 New Messages</p>
              <a href="javascript:;" class="text-muted">Clear all</a>
            </div>
            <div class="p-1">
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div class="me-3">
                  <img
                    class="wd-30 ht-30 rounded-circle"
                    src="https://via.placeholder.com/30x30"
                    alt="userr"
                  />
                </div>
                <div class="d-flex justify-content-between flex-grow-1">
                  <div class="me-4">
                    <p>Leonardo Payne</p>
                    <p class="tx-12 text-muted">Project status</p>
                  </div>
                  <p class="tx-12 text-muted">2 min ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div class="me-3">
                  <img
                    class="wd-30 ht-30 rounded-circle"
                    src="https://via.placeholder.com/30x30"
                    alt="userr"
                  />
                </div>
                <div class="d-flex justify-content-between flex-grow-1">
                  <div class="me-4">
                    <p>Carl Henson</p>
                    <p class="tx-12 text-muted">Client meeting</p>
                  </div>
                  <p class="tx-12 text-muted">30 min ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div class="me-3">
                  <img
                    class="wd-30 ht-30 rounded-circle"
                    src="https://via.placeholder.com/30x30"
                    alt="userr"
                  />
                </div>
                <div class="d-flex justify-content-between flex-grow-1">
                  <div class="me-4">
                    <p>Jensen Combs</p>
                    <p class="tx-12 text-muted">Project updates</p>
                  </div>
                  <p class="tx-12 text-muted">1 hrs ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div class="me-3">
                  <img
                    class="wd-30 ht-30 rounded-circle"
                    src="https://via.placeholder.com/30x30"
                    alt="userr"
                  />
                </div>
                <div class="d-flex justify-content-between flex-grow-1">
                  <div class="me-4">
                    <p>Amiah Burton</p>
                    <p class="tx-12 text-muted">Project deatline</p>
                  </div>
                  <p class="tx-12 text-muted">2 hrs ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div class="me-3">
                  <img
                    class="wd-30 ht-30 rounded-circle"
                    src="https://via.placeholder.com/30x30"
                    alt="userr"
                  />
                </div>
                <div class="d-flex justify-content-between flex-grow-1">
                  <div class="me-4">
                    <p>Yaretzi Mayo</p>
                    <p class="tx-12 text-muted">New record</p>
                  </div>
                  <p class="tx-12 text-muted">5 hrs ago</p>
                </div>
              </a>
            </div>
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-center border-top"
            >
              <a href="javascript:;">View all</a>
            </div>
          </div>
        </li> -->
        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="notificationDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <font-awesome-icon icon="bell" size="lg" />
            <div class="indicator">
              <div class="circle"></div>
            </div>
          </a>
          <div class="dropdown-menu p-0" aria-labelledby="notificationDropdown">
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom"
            >
              <p>6 New Notifications</p>
              <a href="javascript:;" class="text-muted">Clear all</a>
            </div>
            <div class="p-1">
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div
                  class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                >
                  <i class="icon-sm text-white" data-feather="gift"></i>
                </div>
                <div class="flex-grow-1 me-2">
                  <p>Ajouter les information de votre store</p>
                  <p class="tx-12 text-muted">30 min ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div
                  class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                >
                  <i class="icon-sm text-white" data-feather="alert-circle"></i>
                </div>
                <div class="flex-grow-1 me-2">
                  <p>Configuration stripe</p>
                  <p class="tx-12 text-muted">1 hrs ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div
                  class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                >
                  <img
                    class="wd-30 ht-30 rounded-circle"
                    src="https://via.placeholder.com/30x30"
                    alt="userr"
                  />
                </div>
                <div class="flex-grow-1 me-2">
                  <p>ajouter votre premier produit</p>
                  <p class="tx-12 text-muted">2 sec ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div
                  class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                >
                  <i class="icon-sm text-white" data-feather="layers"></i>
                </div>
                <div class="flex-grow-1 me-2">
                  <p>Configuration des horaire de travaill</p>
                  <p class="tx-12 text-muted">5 hrs ago</p>
                </div>
              </a>
              <a href="javascript:;" class="dropdown-item d-flex align-items-center py-2">
                <div
                  class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                >
                  <i class="icon-sm text-white" data-feather="download"></i>
                </div>
                <div class="flex-grow-1 me-2">
                  <p>Accepter votre premier commande</p>
                  <p class="tx-12 text-muted">6 hrs ago</p>
                </div>
              </a>
            </div>
            <div
              class="px-3 py-2 d-flex align-items-center justify-content-center border-top"
            >
              <a href="javascript:;">View all</a>
            </div>
          </div>
        </li> -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <img class="wd-30 ht-30 rounded-circle" src="@/assets/img/user.png" alt="profile" />
          </a>
          <div class="dropdown-menu p-0" aria-labelledby="profileDropdown">
            <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
              <div class="mb-3">
                <img class="wd-80 ht-80 rounded-circle" src="@/assets/img/user.png" alt="" />
              </div>
              <div class="text-center">
                <p class="tx-16 fw-bolder" v-if="selectedUser.storeName">{{ selectedUser.storeName }} </p>

                <p class="tx-16 fw-bolder" v-if="!selectedUser.storeName">{{ user.first_name }} {{ user.last_name }}
                </p>
                <p class="tx-12 text-muted" v-if="!selectedUser.storeName">{{ user.email }}</p>
              </div>
            </div>
            <ul class="list-unstyled p-1">
              <li>
                <router-link :to="{ name: 'profile', params: { UserUUID: getUID } }" class="dropdown-item py-2 pointer">
                  <span class="text-body ms-0">
                    <font-awesome-icon icon="user" size="lg" class="me-2 icon-md" />
                    <span>Profil</span>
                  </span>
                </router-link>
              </li>
              <li class="dropdown-item py-2 pointer" @click.prevent="logOut">
                <a class="text-body ms-0">
                  <font-awesome-icon icon="sign-out-alt" size="lg" class="me-2 icon-md" />
                  <span>Déconnexion</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { onMounted, ref } from 'vue';
import { stripeSrevice } from "@/services/stripe/stripe.service"
import { mapGetters } from 'vuex';
import store from '@/store';
import { watch } from 'vue';
import router from "@/router/index";

export default {
  name: "app-navebar",
  components: {},
  setup() {
    const selectedUser = ref({
      storeName: ""
    });
    const user = ref({
      first_name: "",
      last_name: "",
      email: ""
    });
    watch(
      () => store.getters['auth/selectedUser'],
      (newSelectedUser) => {
        selectedUser.value.storeName = newSelectedUser;
        // Handle the change here
        //console.log('selectedUser changed:', newSelectedUser);
      }
    );

    onMounted(() => {
      // init user
      const userData = store.getters["auth/getUser"] ?? null;
      if (userData) {
        user.value.first_name = userData.first_name;
        user.value.last_name = userData.last_name;
        user.value.email = userData.email;
        selectedUser.value.storeName = localStorage.getItem('selectedUser', null) ? selectedUser.value.storeName  :  localStorage.getItem('selectedUser', null);
        //console.log(user.value);
        const { getStripeStatus, stripeState } = stripeSrevice(store.getters['auth/getUuid']);
        getStripeStatus().then(resp => {
          //console.log(stripeState.data.active);
          if (stripeState && stripeState.data && !stripeState.data.active && stripeState.data.link) {
            //console.log("stripeState.data.link", stripeState.data.link)
            this.stripeEnabled = true;
            this.stripeLink = stripeState.data.link.url;
            //console.log("stripeState.data.link", stripeState.data.link.url)
          }
        }).catch(err => {

        });
      }
    });
    return {
      selectedUser,
      user,
      stripeEnabled: null,
      stripeLink: null,
      message: "",
    };
  },
  computed: {
    ...mapGetters(['auth/role', 'auth/getUuid']),
    getUID() {
      return router.currentRoute.value.params.UserUUID;
    },
  },
  props: {
    stripe: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: ""
    }
  },
  methods: {
    // Sign out method
    async logOut() {
      await this.$store.dispatch("auth/logout").then(
        () => {
          this.$router.push("/login");
        },
        (error) => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.$swal({
            title: this.message,
            icon: "error",
            position: "top-end",
            timer: "5000",
            allowOutsideClick: false,
            showConfirmButton: false,
            toast: true,
            showDenyButton: false,
            showCancelButton: false,
            showCloseButton: true,
            timerProgressBar: true,
          });
        }
      );
    },
    toggle() {
      var body = $("body");
      $(".sidebar-header .sidebar-toggler").toggleClass("active not-active");
      if (window.matchMedia("(min-width: 992px)").matches) {
        body.toggleClass("sidebar-folded");
      } else if (window.matchMedia("(max-width: 991px)").matches) {
        body.toggleClass("sidebaropen");
      }
    },
  },
};

</script>
<style lang="scss">
@import "~@/sass/theme/light.scss";
@import "~@/sass/theme/dark.scss";
@import "~@/sass/variables.scss";

.pointer {
  cursor: pointer;
}

.navbar {
  width: calc(100% - #{$sidebar-width-lg});
  height: $navbar-height;
  background: $card-bg;
  border-bottom: 1px solid $border-color;
  display: flex;
  align-items: center;
  padding: 0;
  position: fixed;
  right: 0;
  left: $sidebar-width-lg;
  z-index: 978;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  transition: width 0.1s ease, left 0.1s ease;

  @media (max-width: 991px) {
    width: 100%;
    left: 0;

    .navbar-content {
      width: calc(100% - #{$sidebar-folded-width} - 1px);
    }
  }

  .sidebar-toggler {
    height: 100%;
    border-right: 1px solid $border-color;
    align-items: center;
    padding: 0 25px;
    display: none;

    svg {
      width: 20px;
      height: 20px;
      color: $text-muted;
    }

    @media (max-width: 991px) {
      display: flex;
    }
  }

  .search-form {
    @extend .d-none !optional;
    @extend .d-md-flex !optional;
    @extend .align-items-center !optional;
    width: 100%;
    margin-right: 60px;

    .input-group {
      .input-group-text {
        padding: 0;
        border: 0;
        color: $text-muted;
        background: $input-bg;

        svg {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }

      .form-control {
        border: 0;
        margin-top: 3px;
      }
    }
  }

  .text-wh {
    color: #FFF;
  }

  .navbar-content {
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 25px;
    padding-right: 25px;

    @media (max-width: 991px) {
      width: calc(100% - #{$sidebar-folded-width} - 1px);
    }

    .navbar-nav {
      display: flex;
      flex-direction: row;
      margin-left: auto;

      .nav-item {
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        min-width: 30px;
        display: flex;
        align-items: center;

        .nav-link {
          color: $body-color;
          padding: 0;
          position: relative;
          margin-left: auto;
          margin-right: auto;

          &:hover,
          &[aria-expanded="true"] {
            color: $primary;
          }

          &::after {
            display: none;
          }

          svg {
            width: 20px;
            height: 20px;
          }

          .indicator {
            position: absolute;
            top: 0px;
            right: 2px;

            .circle {
              background: $primary;
              width: 7px;
              height: 7px;
              border-radius: 50%;

              &::before {
                background-color: $primary;
                content: "";
                display: table;
                border-radius: 50%;
                position: absolute;
                @extend .pulse !optional;
              }
            }
          }
        }

        &.dropdown {
          @media (max-width: 767px) {
            position: static;
          }

          .dropdown-menu {
            width: max-content;
            position: absolute;
            right: -20px;
            left: auto;
            @extend .dropdownAnimation !optional;

            &::before {
              content: "";
              width: 13px;
              height: 13px;
              background: $dropdown-bg;
              position: absolute;
              top: -7px;
              right: 28px;
              transform: rotate(45deg);
              border-top: 1px solid $dropdown-border-color;
              border-left: 1px solid $dropdown-border-color;
            }

            @media (max-width: 767px) {
              right: 20px;
              width: calc(100% - 40px);

              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
