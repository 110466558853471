import { createApp } from 'vue'
import 'maz-ui/css/main.css'
import { createI18n } from "vue3-i18n";
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'

//import "bootstrap/dist/css/bootstrap.min.css"
//import "bootstrap"

import store from '@/store'
import { FontAwesomeIcon } from './plugins/font-awesome'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import swal from "./plugins/swal";
import print from 'vue3-print-nb'
//import VueApexCharts from "vue3-apexcharts";
import axios from 'axios';
import '@/services/axios/interceptors'
import profileimage from 'src/pages/store/images/profileImage.js'
require('@/store/subscriber');
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API + 'api/';
// Get user and token from localStorage
store.commit('auth/loginSuccess', JSON.parse(localStorage.getItem('user')!))

var lang = localStorage.getItem('selectedLang') || 'fr'
lang = lang != '' ? lang : 'fr'
const i18n = createI18n({
  locale: lang,
  messages: {
    fr: require('@/i18n/fr.json'),
    en: require('@/i18n/en.json')
  }
});
const app = createApp(App)
app.use(router)
app.use(store)
app.use(print)
app.use(swal);
//app.use(VueApexCharts);
app.use(VueSweetalert2)
app.use(i18n)
  .component("font-awesome-icon", FontAwesomeIcon)

import CustomModalComponent from './pages/order/elements/CustomModal.vue';
import CustomDialogConfirmation from './pages/order/elements/CustomDialogConfirmation.vue';
import CartComponent from './pages/order/cart.vue';
import CustomModalAddToCart from "./pages/order/CustomModalAddToCart.vue"
import PromosComponent from "./pages/order/Promos.vue"
import ModalTablesComponent from "./pages/order/tables.vue"

app.component('cart-component', CartComponent);
app.component('custom-modal-add-to-cart', CustomModalAddToCart);
app.component('promos-component', PromosComponent);
app.component('modal-tables-component', ModalTablesComponent)
app.component('CustomModal', CustomModalComponent)
app.component('dialog-confirmation', CustomDialogConfirmation)

app.mount('#app')