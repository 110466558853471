// @ts-nocheck
import store from '../store/storeOrder/index'
export function setDefaultImage(event, defaultImage, imagePath) {
  const img = new Image();
  img.src = imagePath;
  img.onerror = () => {
    event.target.src = process.env.VUE_APP_DEFAULT_IMAGE
  };
}

export function setDefaultBackgroundImage(event, defaultImage, imagePath) {
  const img = new Image();
  img.src = imagePath;
  img.onerror = () => {
    event.target.style.backgroundImage = `url(${defaultImage})`;
  };
}

export function getImageUrl(pathImage) {
  console.log(process.env.VUE_APP_ROOT_API)
  if (pathImage) {
    return process.env.VUE_APP_ROOT_API + pathImage;
  } else {
    return process.env.VUE_APP_DEFAULT_IMAGE
  }
}


export function openSideRight() {
  store.state.isSideRightOpening = !store.state.isSideRightOpening
  store.state.isSideLeftOpening = false
  if (store.state.isSideRightOpening) {
    document.body.classList.add('modal-open');
  } else {
    document.body.classList.remove('modal-open');
    document.querySelector('.liv-dropleft-cart').classList.remove('open-cart')
  }
}


export function openSideLeft() {
  store.state.isSideLeftOpening = !store.state.isSideLeftOpening
  store.state.isSideRightOpening = false
  if (store.state.isSideLeftOpening) {
    document.body.classList.add('modal-open');
  } else {
    document.body.classList.remove('modal-open');
    document.querySelector('.liv-dropright-cart').classList.remove('open-cart')
  }
}
