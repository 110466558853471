<template>
  <nav class="sidebar">
    <div class="sidebar-header">
      <div class="row col-12 d-flex justify-content-center mb-1 mt-1"><img class="logo" src="/img/logo.png" /></div>
      <div class="sidebar-toggler not-active" @click.prevent="toggle">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body">
      <ul class="nav mb-0" v-if="getUser">
        <!-- list Users -->
        <li v-if="isRoleSuperAdmin" class="nav-item">
          <router-link :to="{ name: 'users', params: { UserUUID: this.$store.getters['auth/getUuid'] } }"
            class="nav-link">
            <font-awesome-icon icon="user" size="lg" class="link-icon" />
            <span class="link-title">{{ $t("layout.sidbar.users") }}</span>
          </router-link>
        </li>
        <li v-if="isRoleSuperAdmin" class="nav-item">
          <router-link :to="{ name: 'copyCat' }" class="nav-link">
            <font-awesome-icon icon="copy" size="lg" class="link-icon" />
            <span class="link-title">{{ $t("layout.sidbar.copycat") }}</span>
          </router-link>
        </li>

        <li class="nav-item nav-category">{{ $t("layout.sidbar.commandesG") }}</li>
        <!-- <li v-if="!isRoleSuperAdmin" class="nav-item">
          <router-link :to="{ name: 'dashboard', params: { UserUUID: getUser.uuid } }" class="nav-link">
            <font-awesome-icon icon="chart-line" size="lg" class="link-icon" />
            <span class="link-title">{{ $t("layout.sidbar.dashboard") }}</span>
          </router-link>
        </li> AddToCart-->

        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item ">
          <router-link :to="{ name: 'orders', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="bell" size="lg" class="link-icon" />
            <span class="link-title ">{{ $t("layout.sidbar.commandes") }}</span>
          </router-link>
        </li>
        <li v-if="!isRoleSuperAdmin" class="nav-item ">
          <router-link :to="{ name: 'AddToCart', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="store" size="lg" class="link-icon" />
            <span class="link-title ">{{ $t("layout.sidbar.managercommande") }}</span>
          </router-link>
        </li>


        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item nav-category">{{ $t("layout.sidbar.product") }}
        </li>
        <li v-if="isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'categories', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="sitemap" size="lg" class="link-icon" />
            <span class="link-title">{{ $t("layout.sidbar.categories") }}</span>
          </router-link>
        </li>

        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'products', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="cubes" size="lg" class="link-icon" />
            <span class="link-title">{{ $t("layout.sidbar.product") }}</span>
          </router-link>
        </li>
        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'ingredients', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="check-square" size="lg" class="link-icon" />
            <span class="link-title">{{ $t("layout.sidbar.ingredients") }}</span>
          </router-link>
        </li>
        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'supplements', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="plus-circle" size="lg" class="link-icon" />
            <span class="link-title">{{ $t("layout.sidbar.supplements") }}</span>
          </router-link>
        </li>
        <li v-if="isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'copyProduct', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="copy" size="lg" class="link-icon" />
            <span class="link-title">copier un groupe</span>
          </router-link>
        </li>
        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item nav-category">Gestion store</li>
        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'tables', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="chair" size="lg" class="link-icon" />
            <span class="link-title">{{ $t("layout.sidbar.tables") }}</span>
          </router-link>
        </li>

        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'fees', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="money-bill" size="lg" class="link-icon" />
            <span class="link-title">Gestion des frais</span>
          </router-link>
        </li>

        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'qrcode', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="qrcode" size="lg" class="link-icon" />
            <span class="link-title">Qr Code</span>
          </router-link>
        </li>
        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'notification', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="bell" size="lg" class="link-icon" />
            <span class="link-title">Notification</span>
          </router-link>
        </li>
        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'Client', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="user" size="lg" class="link-icon" />
            <span class="link-title">Clients</span>
          </router-link>
        </li>
        <li v-if="isRoleAdmin" class="nav-item nav-category">Gestion des promotions</li>
        <li v-if="isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'promos', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="percent" size="lg" class="link-icon" />
            <span class="link-title">{{ $t("layout.sidbar.promos") }}</span>
          </router-link>
        </li>

        <li v-if="isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'promocode', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="percent" size="lg" class="link-icon" />
            <span class="link-title">{{ $t("layout.sidbar.promocode") }}</span>
          </router-link>
        </li>

        <li v-if="isRoleAdmin" class="nav-item nav-category">Images</li>
        <li v-if="isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'images-bank', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="share" size="lg" class="link-icon" />
            <span class="link-title">Banque des images</span>
          </router-link>
        </li>

        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item nav-category">Fidélisation</li>
        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'loyality', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="mail-bulk" size="lg" class="link-icon" />
            <span class="link-title">Paramètres de fidélisation</span>
          </router-link>
        </li>

        <li class="nav-item nav-category">Contact</li>
        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'contact', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="mail-bulk" size="lg" class="link-icon" />
            <span class="link-title">Nous contacter</span>
          </router-link>
        </li>

        <li v-if="!isRoleSuperAdmin || isRoleAdmin" class="nav-item">
          <router-link :to="{ name: 'services', params: { UserUUID: getUID } }" class="nav-link">
            <font-awesome-icon icon="fighter-jet" size="lg" class="link-icon" />
            <span class="link-title">{{ $t("layout.sidbar.boostInside") }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import store from '@/store';

import router from "@/router/index";
import { onMounted } from 'vue'
export default {
  name: "app-sidebar",

  components: {},


  computed: {
    getUser() {
      return this.$store.getters['auth/getUser']
    },
    getUID() {
      return router.currentRoute.value.params.UserUUID;
    },
    isRoleSuperAdmin() {
      return this.$store.getters['auth/role'] // return true when role == super-admin
    },
    isRoleAdmin() {
      return this.getUID != this.$store.getters['auth/getUuid']
    },

  },
  methods: {
    toggle() {
      var body = $("body");
      $(".sidebar-header .sidebar-toggler").toggleClass("active not-active");
      if (window.matchMedia("(min-width: 992px)").matches) {
        body.toggleClass("sidebar-folded");
      } else if (window.matchMedia("(max-width: 991px)").matches) {
        body.toggleClass("sidebar-open");
      }
    },
  },

};
</script>
<style >
.home-list {
  background-color: #ebdfec;
  display: flex;
  justify-content: center;
}

:not(.sidebar-folded) .home-link-color {
  color: #7a147a !important;
}

.sidebar-folded .home-list {
  background-color: inherit !important;
  display: block !important;

}

.sidebar-folded .nav-item {
  width: 30px;
}

.logo {
  max-height: 83px;
  max-width: 83px;
}
</style>